import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { superRed, white } from '@engage/colors';
import { If } from '@engage/conditional-render';
import { spaces } from '@engage/styles';
import { useFeedNotificationsData } from '@members/pg-feed/hooks';
import { Notifications } from '../../icons/Notifications';

export const NotificationsBadge: React.FC<
  React.PropsWithChildren<{ isNotificationsOpen: boolean }>
> = ({ isNotificationsOpen }) => {
  const { quantityOfNotifs, refetch } = useFeedNotificationsData();

  React.useEffect(() => {
    refetch();
  }, [isNotificationsOpen]);
  return (
    <>
      <StyledNotifications />
      <If condition={quantityOfNotifs > 0}>
        <Badge quantity={quantityOfNotifs}>
          <Digit data-test-id="notificationButtonCount">{quantityOfNotifs}</Digit>
        </Badge>
      </If>
    </>
  );
};

const Digit = styled.p`
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: ${white};
  height: 24px;
  ${media.tablet`
    height: 14px;
    display: inline-flex;
  `};
`;
const Badge = styled.div<{ quantity: number }>`
  position: absolute;
  object-fit: contain;
  background-color: ${superRed};
  border-radius: ${spaces.xxLarge}px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  margin-bottom: 18px;
  width: ${({ quantity }) => (quantity > 9 ? `20px` : '16px')};
  height: 16px;
`;

const StyledNotifications = styled(Notifications)`
  padding-top: 2px;
  height: 24px;
  width: 24px;
  margin-right: 8px;
  ${media.tablet`
    padding-left: 2px;
    margin-right: 0px;
  `};
`;
