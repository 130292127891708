import React from 'react';
import { FocusOn } from 'react-focus-on';
import styled from 'styled-components';
import { toDevice } from '@peloton/browser';
import { GreaterThan, LessThan, getIsBreakpointLessThan } from '@peloton/responsive';
import { blue2, slate1 } from '@engage/colors';
import type { TopNavUserInfo } from '@engage/members';
import { useReduxState } from '@engage/redux';
import { spaces } from '@engage/styles';
import { label14 } from '@engage/typography';
import { Feature, useFeatureToggle } from '@members/feature-toggles';
import type { SkipLinksProps } from '@members/layout/SkipLinks';
import { GetAppModal, useGetApps } from '@members/modal-get-app';
import { StackNotification } from '@members/stacks/StackNotification';
import { useIsFreemiumMember } from '@members/tiering/hooks/useIsFreemiumMember';
import { MainLink } from '../../sharedLinkStyles';
import { SkipLinks } from '../../SkipLinks';
import { topNavContainerStyles, TOP_NAV_HEIGHT_PX } from '../containers';
import { HomeLogoLink } from './HomeLogoLink';
import { MemberSearchFromNav } from './MemberSearchFromNav';
import { miniNavLinkId } from './MiniNavBlock';
import { MiniNavBlock } from './MiniNavBlock/MiniNavBlock';
import { MiniNavIcons } from './MiniNavBlock/MiniNavIcons';
import { MobileNavDrawer } from './MobileNavDrawer/MobileNavDrawer';
import TopNavGuest from './TopNavGuest';
import TopNavLinks from './TopNavLinks';
import { UpsellCTA } from './UpsellCTA';

type Props = SkipLinksProps & {
  isUserSignedIn: boolean;
  isMenuOpen: boolean;
  openModal(name: string): void;
  handleMenuOpen(): void;
  handleMenuClose(e?: Event): void;
};

type TopNavProps = Props & { user?: TopNavUserInfo };
type MemberProps = Omit<TopNavProps, 'isUserSignedIn'> & { user: TopNavUserInfo };

const TopNavView: React.FC<React.PropsWithChildren<TopNavProps>> = ({
  isUserSignedIn,
  isMenuOpen,
  openModal,
  handleMenuOpen,
  handleMenuClose,
  skipLinks,
  user,
}) => {
  const isOnHomescreenPage = window.location.pathname.split('/').includes('home');

  return (
    <Container isOnHomescreenPage={isOnHomescreenPage}>
      {user && isUserSignedIn ? (
        <MemberTopNav
          skipLinks={skipLinks}
          user={user}
          isMenuOpen={isMenuOpen}
          openModal={openModal}
          handleMenuOpen={handleMenuOpen}
          handleMenuClose={handleMenuClose}
        />
      ) : (
        <TopNavGuest />
      )}
      <MemberSearchFromNav focusElement={miniNavLinkId} />
    </Container>
  );
};

const MemberTopNav: React.FC<React.PropsWithChildren<MemberProps>> = ({
  skipLinks,
  isMenuOpen,
  openModal,
  handleMenuOpen,
  user,
  handleMenuClose,
}) => {
  const navUpdates = useFeatureToggle(Feature.Navupdates);
  const [isGetAppModalOpen, setIsGetAppModalOpen] = React.useState<boolean>(false);
  const device = toDevice();
  const hasGetApps = useGetApps();
  const memberRef = React.useRef() as React.RefObject<any>;
  const navBreakpoint = navUpdates ? 'desktopLarge' : 'desktop';
  const mobileDrawerBreakpoint = navUpdates ? 'desktopXLarge' : 'desktopLarge';
  const phoneBreakpoint = 'smallScreen';
  const useMobileDrawer = useReduxState(getIsBreakpointLessThan, mobileDrawerBreakpoint);
  const isFreemiumMember = useIsFreemiumMember();

  const toggleMenuOpen = () => {
    isMenuOpen ? handleMenuClose() : handleMenuOpen();
  };

  return (
    <>
      <TopContainer isMenuOpen={isMenuOpen} isMobile={useMobileDrawer}>
        <StackNotification />
        <MainBlock>
          <HomeLogoLink />
          <GreaterThan breakpoint={navBreakpoint}>
            <CenterLinksContainer data-test-id="topNavLinksContainer">
              <TopNavLinks />
            </CenterLinksContainer>
          </GreaterThan>
          <SkipLinks
            skipLinks={skipLinks}
            StyledLink={StyledSkipLink}
            SkipContainer={SkiplinksContainer}
          />
          <ItemContainer>
            <GreaterThan breakpoint="tabletLarge">
              {isFreemiumMember ? <UpsellCTA /> : null}
            </GreaterThan>
            <GreaterThan breakpoint={phoneBreakpoint}>
              <MiniNavIcons user={user} />
            </GreaterThan>
            <FocusOn enabled={isMenuOpen} shards={[memberRef]}>
              <MiniNavBlock
                user={user}
                onClick={toggleMenuOpen}
                isMenuOpen={isMenuOpen}
                handleMenuClose={handleMenuClose}
                handleOpenGetApp={() => setIsGetAppModalOpen(true)}
              />
            </FocusOn>
          </ItemContainer>
        </MainBlock>
      </TopContainer>
      <LessThan breakpoint={mobileDrawerBreakpoint}>
        <ItemContainer ref={memberRef}>
          <MobileNavDrawer
            isOpen={isMenuOpen}
            handleMenuClose={handleMenuClose}
            handleOpenGetApp={() => setIsGetAppModalOpen(true)}
          />
        </ItemContainer>
      </LessThan>
      {hasGetApps ? (
        <GetAppModal
          device={device}
          isOpen={isGetAppModalOpen}
          closeHandler={() => setIsGetAppModalOpen(false)}
        />
      ) : null}
    </>
  );
};

const ItemContainer = styled.div<{ ref?: React.RefObject<any> }>`
  align-items: center;
  display: flex;

  &:first-child {
    justify-content: flex-start;
  }

  &:last-child {
    justify-content: flex-end;
  }
`;

const CenterLinksContainer = styled(ItemContainer)`
  flex: 3 0 0%;
  justify-content: left;
`;

const TopContainer = styled.div<{ isMenuOpen: boolean; isMobile: boolean }>`
  ${topNavContainerStyles}
  ${p =>
    p.isMenuOpen &&
    p.isMobile &&
    `  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.00); //lights/shadow
`}
`;

const Container = styled.nav<{ isOnHomescreenPage: boolean }>`
  position: relative;
  width: 100%;
  z-index: 3;
  height: ${TOP_NAV_HEIGHT_PX}px;
`;

const MainBlock = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
`;
MainBlock.displayName = 'MainBlock';

const SkiplinksContainer = styled.div`
  display: flex;
  position: relative;
  top: 0;
  width: calc(100% - ${spaces.large}px);
`;

const StyledSkipLink = styled.a`
  ${label14}
  ${MainLink}

  font-weight: 900;
  position: absolute;
  padding: 0 ${spaces.medium}px;
  height: ${spaces.xHuge}px;
  max-height: ${spaces.xHuge}px;
  left: -100vw;
  z-index: 100;

  box-sizing: border-box;
  flex: none;
  order: 0;
  flex-grow: 0;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  white-space: no-wrap;
  overflow: hidden;

  &:focus {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    color: ${slate1};
    outline-color: ${blue2} !important;
  }
`;

export default TopNavView;
