import React from 'react';
import styled from 'styled-components';
import { GreaterThan } from '@peloton/responsive';
import { media } from '@peloton/styles';
import { black } from '@engage/colors';
import { spaces } from '@engage/styles';
import { label14 } from '@engage/typography';
import { TopNav, TopNavCopy } from '@members/copy';
import { useFeatureToggle, Feature } from '@members/feature-toggles';
import { HOMESCREEN_ROUTE } from '@members/pg-homescreen/Routes';
import { MainLink, makeLinkActivatable } from '../../sharedLinkStyles';
import { ANALYTICS_SOURCE } from '../analytics';
import { PelotonLogo } from '../icons/PelotonLogo';

export const HomeLogoLink = () => {
  const navUpdatesEnabled = useFeatureToggle(Feature.Navupdates);
  const navBreakpoint = navUpdatesEnabled ? 'desktopLarge' : 'desktop';
  return (
    <ActivatableMainLink
      to={{
        pathname: HOMESCREEN_ROUTE,
        state: { source: ANALYTICS_SOURCE },
      }}
      data-test-id="homepageLink"
    >
      <NavPelotonLogo />
      <GreaterThan breakpoint={navBreakpoint}>
        <TopNavCopy id={TopNav.Home} />
      </GreaterThan>
    </ActivatableMainLink>
  );
};
const NavPelotonLogo = styled(PelotonLogo)`
  fill: ${black};
  height: 32px;
  width: 23px;
  margin-left: 0px;
  ${media.tablet`
    height: 28px;
    width: ${spaces.large}px;
  `}
  ${media.desktopLarge`
    margin-right: ${spaces.small}px;
  `}
`;

const TopTextLink = styled(MainLink)`
  ${label14};
  margin-bottom: 0px;
  padding: 10px ${spaces.medium}px;
  border-radius: 4px;
`;

const ActivatableMainLink = makeLinkActivatable(TopTextLink);
